body {
  padding: 0 !important;
  margin: 0 !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px !important;
}

a {
  text-decoration: none;
  color: black;
}
.intro{
  background-color: #6ABF57;
  min-height: 150px;
  max-width: 764px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.intro-img{
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 25px;
  max-width: 500px;
  letter-spacing: 3px;
}

@media (max-width: 800px) {
  .intro-img {
    max-width: 300px;
  }
}


.intro-main{
box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
border-radius: 20px;
display: block;
margin-left: auto;
margin-right: auto;
width: 359px;
height: 30px;
background-color: #FFFFFF;
margin-top: 15px;
}
.intro-main-p{
  font-family: 'Arial';
font-style: normal;
font-weight: 900;
font-size: 14px;
line-height: 20px;
text-align: center;
padding-top: 5px;
margin-top: 10px;
padding-bottom: 10px;
}
.intro-tag{
  color: #FFFFFF;
  width: 360px;
  height: 12px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1px;
  padding-left: 10px;
}
.footer{
  width: 100%;
  max-width: 764px;
  margin: 20px auto auto auto;
}
.visit{
  min-height:50px;
  background: whitesmoke;
border-radius: 5px;
margin-bottom: 20px;
}
.visit-text{
  font-family: 'Arial' !important;
font-style: normal;
font-weight: 700;
font-size: 20px !important;
line-height: 23px;
text-align: center;
text-transform: uppercase;
padding-top: 15px;
color: #273443;
}

.westmfg{
  min-height:50px;
  background: #273443;
border-radius: 5px;
}
.westmfg-text{
  font-family: 'Arial' !important;
font-style: normal;
font-weight: 700;
font-size: 20px !important;
line-height: 23px;
text-align: center;
text-transform: uppercase;
color: white;
padding-top: 15px;
}
.header {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  background-color: #273443;
  /* width: 764px; */
  margin: auto;
}
.header-text{
  font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 21px;
text-align: center;
color: #FFFFFF;
padding-top: 20px;
}

.header img {
  height: 30px;
  margin-top: 20px;
}

.header__title {
  font-size: 18px;
  text-align: center;
}

.header__link {
  font-weight: 500;
  font-size: 13px;
  text-transform: none;
  color: #273443;
}

.btn {
  border: 1px solid transparent !important;
  padding: 11px 25px !important;
  line-height: initial !important;
  border-radius: 0 !important;
  -webkit-transition: 0.5s !important;
  transition: 0.5s !important;
  text-transform: uppercase !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.btn.disabled,
.btn:disabled {
  opacity: 1 !important;
  cursor: not-allowed !important;
}

.btn-primary {
  color: #273443 !important;
  background-color: #6CBF57 !important;
  border-color: #6CBF57 !important;
  border-radius: 40px !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #273443 !important;
  background-color: #6CBF57 !important;
  border-color: #6CBF57 !important;
  cursor: not-allowed !important;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  background-color: transparent !important;
  color: #6CBF57 !important;
  border-color: #6CBF57 !important;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
}

.btn-primary:hover,
.btn-primary:focus {
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  background-color: #6CBF57 !important;
  color: #fff !important;
  border-color: #6CBF57 !important;
}

/* ===========================
   Password modal
=========================== */
.pw-modal {
  opacity: 0;
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, .5);
  pointer-events: none;
  transition: opacity .4s ease-in-out;
}

.pw-modal-content {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  color: #333;
  margin: 5rem auto;
  width: 40%;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
  border-radius: 5px;
  transform: translateY(-40px);
  transition: transform .3s ease-out;
  z-index: 2001;
  text-align: center;
}

/* Modal Header */
.pw-modal-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 25px 20px;
}

/* Close Button */
span.pw-modal-close {
  color: gainsboro;
  font-size: 30px;
  line-height: 0;
}

.pw-modal-close:hover,
.pw-modal-close:focus {
  color: #333;
  cursor: pointer;
}

/* Modal Body */
.pw-modal-body {
  padding-top: 10px;
  padding-bottom: 30px;
}

.pw-modal-body p {
  margin-left: 10px;
  margin-right: 10px;
}

.pw-modal-body form {
  padding-top: 20px;
  margin: 0 auto;
}

.pw-modal-body form input {
  margin: 0 auto;
  display: block;
  width: 200px;
  margin-bottom: 10px;
  padding: 5px 10px;
  border: solid 1px #e6e8eb;
}

.pw-modal-body form button {
  margin-top: 20px;
}

.pw-modal-body .form-error {
  font-size: 14px;
  color: #E10C19;
}

/* When modal is open */
.pw-modal.show {
  opacity: 1;
  pointer-events: auto;
}

.pw-modal.show .pw-modal-content {
  transform: none;
}

@media only screen and (max-width: 500px) {
  .pw-modal-content {
    width: 90%;
    margin: 45% auto;
  }

  .pw-modal-body {
    padding-top: 3px;
    padding-bottom: 30px;
  }
}


/* ===========================
   Pdf overlay
=========================== */

.catalog-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

}

.catalogs-item {
  position: relative;
  margin: 20px;
  width: 360px;
height: 575px;
  background: #FFFFFF;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.see-items{
  width: 334px;
  font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 25px;
text-align: center;
text-transform: uppercase;
margin: auto;
padding-top: 15px;
}
/* .see-items:hover{
  color: #FF0000;
  cursor: pointer;
} */

.open-item{
width: 334px;
height: 49px;
display: flex;
background: rgba(39, 52, 67, 0.1);
border-radius: 5px;
margin: 15px auto auto auto;
justify-content: center;
align-items: center;
}

.open-item-text{
  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
padding-top: 15px;
}
.open-item-text:hover{
  color: #FF0000;
  cursor: pointer;
}

.catalogs-item img {
  z-index: 10;
  width: 333px;
  margin: 20px auto 0 auto;
  display: block;
}

.catalogs-heading {
  font-size: 22px !important;
  font-weight: bolder !important;
  color: #273443 !important;
  text-align: center !important;
}

.catalogs-item:hover .catalogs-overlay {
  display: flex !important;
}

.catalogs-overlay {
  position: absolute !important;
  background: rgba(0, 0, 0, 0.6) none repeat scroll 0 0 !important;
  width: 100% !important;
  height: 0 !important;
  transition: 0.5s ease !important;
  z-index: 0 !important;
  top: 0% !important;
  height: 100% !important;
  display: none !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.catalogs-overlay h1 {
  color: #ffffff !important;
  font-size: 16px !important;
  padding-bottom: 10px !important;
}

.catalogs-overlay-content {
  bottom: 0 !important;
  padding: 15px !important;
  position: absolute !important;
  width: 100% !important;
}

.note-font {
  font-weight: bold !important;
}

.close-button {
  position: absolute !important;
  top: 0px !important;
  right: 0px !important;
  border: none !important;
}

.cagtelog-btn-style {
  background-color: #6CBF57 !important;
  color: #ffffff !important;
  margin: 0 auto !important;
  padding: 7px !important;
  transition: 0.5s !important;
  border: 0px !important;
}

.cagtelog-btn-style:hover {
  background-color: #6ebd44 !important;
  color: #ffffff !important;
}

.cagtelog-btn-style a {
  color: #ffffff !important;
}

.cagtelog-btn-style a i {
  color: #f14d4d !important;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media (max-width: 700px) {
  .catalogs-item {
    width: 100%;
  }
}

@media (max-width: 420px) {
  .header-text {
    font-size: 15px;
  }
}


